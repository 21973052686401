import * as React from 'react';
import {wsURL} from './initialize.js';
import {calc_distance, convertGpsTime, to_d} from '../helpers';
import { isAthlete } from './device_helpers.js';

// WebSocketの初期化、接続
var isOnline = false;
var websocket;
var intervalWSCheck = null;
let updWebsocketTime = null;

export function setDelay(liveDelay){
    if(isOnline){
      websocket.send(liveDelay);//WebSocket送信ディレイを送付
    }
}

export function initializeWebsocket(devices, setDevices, setOnline, token){
		updWebsocketTime = new Date();
    console.log("[WS Endpoint] "+wsURL());

		//Websocketデータの受信をチェック
		let checkWebsocketOnline =() => {
			let now_t = new Date();
			console.log(now_t-updWebsocketTime)
			if( now_t-updWebsocketTime > 15000 ) {
				clearInterval(intervalWSCheck);
				intervalWSCheck = null;
				websocket.onopen = null;
				websocket.onmessage = null;
				websocket.close();
				console.log("ws closed.");
				setOnline(null);
				isOnline = false;
				//alert("Can not recieve data. Connection close. 再接続します。")
				console.log("Can not recieve data. Connection close. 再接続します。")
				initializeWebsocket(devices, setDevices, setOnline, token);
			}
		}


    if(!isOnline){
      isOnline = true;
      websocket = new WebSocket(wsURL());
    }

    websocket.onopen = function(event){
      console.log("ws opened.");
      isOnline = true;
      setOnline({online: isOnline});
      websocket.send(token);
			//WSのデータ受信状態をチェック
			if(!intervalWSCheck){//２周動作チェック
				intervalWSCheck = setInterval(() => {
					checkWebsocketOnline();
				}, 5000);
			}else{
				console.log("Check interval already running")
			}
    }

    websocket.onmessage = function (event) {
			updWebsocketTime = new Date();//online確認用
      let data = JSON.parse(event.data);
      console.log("websocket on message:", data);
			if(data.type==="gps"){
      	move(data, devices, setDevices);
			}
    }

    //Websocket close
    websocket.onclose = function (event) {
      isOnline = false;
      setOnline(null);
      console.log("WebSocket closed.");
      //alert("コネクションが切断(1)。3秒後に再接続します")
      console.log("コネクションが切断(1)。3秒後に再接続します")
      setTimeout(() => {
        initializeWebsocket(devices, setDevices, setOnline, token)
      }, 3000);
    }
}


// デバイス単体の動作制御
function move(data, devices, setDevices){

  let device = devices.get(data.id)

  if(!device)
    return

  //Update device using websocket data
  device.angle = Math.atan2((data.lat-device.lat) , -(data.long-device.lon)) * ( 180 / Math.PI ) + 90 + 180
	if(isAthlete(device.category)){
		let distance = calc_distance(data.lat, data.long, device.lat, device.lon);
		device.updateDistance(distance);
	}

  device.lat = data.lat;
  device.lon = data.long;
	device.updSpeed(data.spd, data.spd_10s, data.spd_5s);

	device.emergency = data.emergency

  device.time = timeFormatGpsToLocal(data);

  //航跡ライン用
  device.pushGps(data.lat, data.long);

  //update devices
  //setDevices(devices => new Map([...devices, [data.id, device]]));
	const updatedMap = new Map(devices);
	updatedMap.set(data.id, device);
	setDevices(updatedMap);
}


function timeFormatGpsToLocal(gps){
  let d = new Date();
  let t = gps.time;
  d.setHours(t.slice(0, 2));
  d.setMinutes(t.slice(2, 4));
  d.setSeconds(t.slice(4, 9));
  d.setTime(d.getTime() + 1000*60*60*9);
  return d.toLocaleTimeString();
}


//debug
function timeFormatGpsToLocalTime(gps){
  let d = new Date();
  let t = gps.time;
  d.setHours(t.slice(0, 2));
  d.setMinutes(t.slice(2, 4));
  d.setSeconds(t.slice(4, 9));
  d.setTime(d.getTime() + 1000*60*60*9);
  return d;
}
