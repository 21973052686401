import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import Map, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  useControl, //for deck.gl
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { isAthlete } from '../../initialize/device_helpers';

//--- Deck GL -----------------------
//import {MapboxLayer} from '@deck.gl/mapbox';
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {TerrainLayer} from '@deck.gl/geo-layers';
import {PathLayer, TextLayer, LineLayer, GeoJsonLayer} from '@deck.gl/layers';
import { LightingEffect, DirectionalLight, AmbientLight } from '@deck.gl/core';
import {Deck} from '@deck.gl/core'; 
import createMarkerLayers from './marker_layers';
//import ParticleLayer from './particle_layers';//風向表示テスト
import ArrowLayer from './wind_arrow';
//--- Deck GL -----------------------


import {lineLayer, StartGoalLineLayer, SymbolLayer} from '../map/lineLayer.js';
import Online from '../map/online.js';
import useRouteVisibility from '../../hooks/useRouteVisibility.js';
import CourseMap from '../map/course_map.js';
import Menu from '../menu/menu.js';
import StartGoalFlag from '../marker/start_goal_flag.js';
import Sups from '../marker/sups.js';
import WindInfo from '../map/wind_info.js';

import {getElevation, interpolatePoints, getNearestElevation } from '../map/get_elevation.js';
import ElevationChart from '../map/elevation_view.js';

const TOKEN = 'pk.eyJ1IjoiaTAxMjEwbWwiLCJhIjoiY2t1NWZ0ZGNhNHp1eTJ2cDMxNXV0OHAwNCJ9.SqRPyXL7O65p-osCqqmp4Q'; // Set your mapbox token here
const geolocateStyle = {
  top: 0,
  left: 0,
  padding: '10px'
};
const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '10px'
};
const navStyle = {
  top: 72,
  left: 0,
  padding: '10px'
};
const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '10px'
};
const pointLayer = {
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#007cbf'
  }
};

const urlParams = new URLSearchParams(window.location.search);
//モジュールロード
var heatCenteredTime = new Date();

	//light
	const ambientLight = new AmbientLight({
		color: [255, 255, 255],
		intensity: 2.5,
	});
	const directionalLight = new DirectionalLight({
		color: [255, 255, 255],
		intensity: 0.8,
		direction: [-1, -1, -1],
	});
	const lightingEffect = new LightingEffect({ ambientLight, directionalLight });
	function DeckGLOverlay(props: MapboxOverlayProps & {
		interleaved?: boolean;
	}) {
		const overlay = useControl(() => new MapboxOverlay({effects: [lightingEffect], ...props})); // エフェクトを追加
		//const overlay = useControl(() => new MapboxOverlay(props));
		overlay.setProps(props);
		return null;
	}



function DeckGLView(props) {
	const mapRef = useRef();
  // Props
  const {devices, setDevices, center, style, online, height, wind, showWindLine, course, setStyle, is_triathlon_view, ele_offset, windLine, setWindLine, ranking, showElevation, clearDevices} = props;
  const [popupInfo, setPopupInfo] = useState(null);
  const [bearing, setBearing] = useState(wind? Number(wind.wind_direction) : urlParams.get("bearing")? Number(urlParams.get("bearing")): 0);
  const [pitch, setPitch] = useState(urlParams.get("pitch")? Number(urlParams.get("pitch")): 60);
  const [geojson, setGeojson] = useState({ });
	const [mapLoaded, setMapLoaded] = useState(false);
	const [elevation, setElevation] = useState(false);
	const [elevationLoaded, setElevationLoaded] = useState(false);
	const [elevationColor, setElevationColor] = useState("#FFFFFF");
	const [showElevationIndexes, setShowElevationIndexes] = useState([]);
	const [prevMap, setPrevMap] = useState(style);


  // カメラをフォーカスするかを判定するフラグ。
  // フォーカス時に地図の中心をカメラに合わせる場合、
  // 「選手のフォーカス機能」と同じロジック(main.js内handleChangeSelectedDevice関数 + 下に続くuseEffectによるcenter変更)にした方が良いかも。
  const [focusCamera, setFocusCamera] = useState(false);


  //--- Mapロード完了後,実行-------------------------------------
	const handleMapLoad = () => {
    setMapLoaded(true);

		if(!showElevation){
			setElevationLoaded(true)
		}
	};
  //--- ElevationData作成後にもとに戻す-------------------------------------
	useEffect(() => {//Revert
		if (elevationLoaded) {
			setStyle(prevMap); // revert map
		}
	}, [elevationLoaded]); // elevationLoaded が変わる度に実行

  //--- ElevationDataの作成-------------------------------------
	useEffect(() => {
		const loadTerrainMap = async () => {
			if(!elevationLoaded){
				setStyle('mapbox://styles/i01210ml/cljpbvcbm005701rdbj1cezho'); // terrain include elevation data
				await fetchElevations(); // fetchElevations の完了を待つ
			}
		}
		const fetchElevations = async () => {
			if (mapLoaded) {
				if (course && course.geojson) {
					if (course.geojson.features[0].geometry) {
						let elevationDatas = [];
						const coordinates = course.geojson.features[0].geometry.coordinates;
						for (let i = 0; i < coordinates.length - 1; i++) {
							const from = coordinates[i];
							const to = coordinates[i + 1];
							const points = interpolatePoints(from[1], from[0], to[1], to[0], 50);
							let j = 0;
							for (const p of points) {
                if(!(isNaN(p[0])||isNaN[1])){
                  const elevationData = await getElevation(p[0], p[1], mapRef.current);
                  elevationDatas.push({ index: j, lon: p[0], lat: p[1], elevation: Math.trunc(elevationData) });
                  j = j + 1;
                }
							}
						}
						setElevation(elevationDatas);
						setElevationColor(course.geojson.features[0].properties.color);
						setElevationLoaded(true);
					}
				}
			}
		};

		if(showElevation){
			loadTerrainMap();
		}
	}, [mapLoaded, course]);




  //---- normal react-map-gl -----------------
  const [viewState, setViewState] = useState({
		latitude: 35.28167445342043,
		longitude: 139.67306039427112,
    zoom: 3,
    pitch: pitch,
  });


  // Heat Center
  useEffect(() => {
    if (center[0] === 35.28167445342043 && center[1] === 139.67306039427112) return;
		setViewState({
			...viewState,
			latitude: center[0],
			longitude: center[1],
			zoom: 3,
			bearing: wind?wind.wind_direction - 360 + bearing:0
		});
		if(mapRef.current){
			setTimeout(()=>{mapRef.current.flyTo({center: [center[1], center[0]], zoom:15, duration: 3000})}, 1000);
		}
  }, [center]);



  useEffect(() => {
    if (center[0] === 35.28167445342043 && center[1] === 139.67306039427112) return;
		if(mapRef.current){
			setTimeout(()=>{mapRef.current.flyTo({center: [center[1], center[0]], zoom:15, duration: 3000})}, 500);
		}
  }, [elevationLoaded]);


   // Marker Center
  let focus_flg = false;
  const [lastFocus, setLastFocus] = useState(null);
  useEffect(() => {
		//HeatのCenteringが終わるまで5秒間、Waitig
    //これがないとview=triathlon時に、HeatのCenteringが終わる前に、MarkerのCenteringが走ってしまう。
		if(new Date() - heatCenteredTime < 3000)
			return;
    //Selected Center
    if(devices){
      let new_center = [0,0];
      let count = 0;
      devices.forEach((device, i)=>{
        if(device.ischecked && device.lat && device.lon){
          new_center[0] = new_center[0] + device.lat;
          new_center[1] = new_center[1] + device.lon;
          count = count + 1;
					if(String(device.id) !== String(lastFocus)){
						focus_flg = true
					} else {
						focus_flg = false
					}
					setLastFocus(device.id)
        }
      });
      if(count > 0){
        new_center[0] = new_center[0] / count;
        new_center[1] = new_center[1] / count;
				if((new_center[0] <= 90) && (new_center[0] >= -90)){
					if(focus_flg){ //選択時
						mapRef.current.flyTo({center: [new_center[1], new_center[0]], duration: 1500, zoom:16});
						heatCenteredTime = new Date();//ズームの時間を稼ぐため
					} else { //常時
						mapRef.current.flyTo({center: [new_center[1], new_center[0]], duration: 1500});
					}
				}
      }
    }
  }, [devices]);

  // route display on/off
  const [visibleRoutes, setVisibleRoutes] = useRouteVisibility(course ? course.geojson : null);
  //Start-Goalライン
  const [StartGoal, setStartGoal] = useState(null);
  // スタート地点の座標を取得するために用意
  const [startTwoCoords, setStartTwoCoords] = useState(null);
  // ゴール地点の座標を取得するために用意
  const [goalTwoCoords, setGoalTwoCoords] = useState(null);
  useEffect(() => {
      let f = [];
      let goal_boat;
      let goal_buoy;
      let start_boat;
      let start_buoy;
      let wind_line = [];
      devices.forEach((device, i)=>{
        if(device.category == 3 && device.disp_name == "S")
          start_buoy = device;
        if(device.category == 4 && device.disp_name == "S")
          start_boat = device;
        if(device.category == 3 && device.disp_name == "G")
          goal_buoy = device;
        if(device.category == 4 && device.disp_name == "G")
          goal_boat = device;
				/*
        //WindLine
        if(wind && showWindLine && windLine && device.id == windLine.deviceId){
          const L = 6371 * 2 * Math.PI / 360 * 1000;
          const lineLength = 1000;
          let x = device.lon;
          let y = device.lat;
          let x1 = x + (lineLength / L) - x;
          let x2 = x - (lineLength / L) - x;
          let deg = -1 * wind.wind_direction * (Math.PI / 180)
          let pos1 = {lon: x1*Math.cos(deg)-0*Math.sin(deg),
                      lat: 0*Math.cos(deg) +x1*Math.sin(deg)};
          pos1.lon = pos1.lon + x;
          pos1.lat = pos1.lat + y;
          let pos2 = {lon: x2*Math.cos(deg)-0*Math.sin(deg),
                      lat: 0*Math.cos(deg) +x2*Math.sin(deg)};
          pos2.lon = pos2.lon + x;
          pos2.lat = pos2.lat + y;
          wind_line.push([pos2.lon, pos2.lat]);
          wind_line.push([pos1.lon, pos1.lat]);
          f.push({type: "Feature", properties:{name: "wind", color: device.color, width: 2, opacity: 0.9},
                  geometry:{type:"LineString",coordinates:[ wind_line[0], wind_line[1] ]}});
        }*/
      });
      //StartLine
      if(start_boat && start_buoy)
        f.push({type: "Feature", properties:{name: "START", color: "#FFFFFF", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [start_boat.lon, start_boat.lat],[start_buoy.lon, start_buoy.lat] ]}});
      //GoalLine
      if(goal_boat && goal_buoy)
        f.push({type: "Feature", properties:{name: "GOAL", color: "#FF0000", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [goal_boat.lon, goal_boat.lat],[goal_buoy.lon, goal_buoy.lat] ]}});
      //Static Goal Line
      if(course){
				//Goal
        f.push({type: "Feature", properties:{name: "GOAL", color: "#FF0000", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [course.goal_start_long, course.goal_start_lat],[course.goal_end_long, course.goal_end_lat] ]}});
        setGoalTwoCoords({coord1: {latitude: course.goal_start_lat, longitude: course.goal_start_long}, coord2: {latitude: course.goal_end_lat, longitude: course.goal_end_long}});
				//Checkpoints
      	course.checkpoints.forEach((cp, i)=>{
					//1個目がスタートかつNET
					if(course.net && i==0){
						//line color:white(start)
        		f.push({type: "Feature", properties:{name: "START", color: "#FFFFFF", width: 4, opacity: 1},
                geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
            setStartTwoCoords({coord1: {latitude: cp.s_lat, longitude: cp.s_lon}, coord2: {latitude: cp.e_lat, longitude: cp.e_lon}});
					}else{
						//line color:Red
						if((cp.check_name == "GS") || (cp.check_name == "GB") || (cp.check_name == "GR")){
							f.push({type: "Feature", properties:{name: cp.check_name, color: "#FF0000", width: 1, opacity: 1},
									geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
						} else {
							//line color:green
							f.push({type: "Feature", properties:{name: cp.check_name, color: "#88FF88", width: 1, opacity: 0.9},
									geometry:{type:"LineString",coordinates:[ [cp.s_lon, cp.s_lat],[cp.e_lon, cp.e_lat] ]}});
						}
					}
				})
			}
 
      setStartGoal({
        type: 'FeatureCollection',
        features: f
      });
  }, [devices, windLine]);

 //航跡ライン：GeoJsonの更新
	const [geojsonVersion, setGeojsonVersion] = useState(0);
  useEffect(() => {
      let f = [];
      let wind_line = [];
      devices.forEach((device, i)=>{
				if(wind && showWindLine && windLine && device.id == windLine.deviceId){
					const L = 6371 * 2 * Math.PI / 360 * 1000;
					const lineLength = 1000;
					let x = device.lon;
					let y = device.lat;
					let x1 = x + (lineLength / L) - x;
					let x2 = x - (lineLength / L) - x;
					let deg = -1 * wind.wind_direction * (Math.PI / 180)
					let pos1 = {lon: x1*Math.cos(deg)-0*Math.sin(deg),
											lat: 0*Math.cos(deg) +x1*Math.sin(deg)};
					pos1.lon = pos1.lon + x;
					pos1.lat = pos1.lat + y;
					let pos2 = {lon: x2*Math.cos(deg)-0*Math.sin(deg),
											lat: 0*Math.cos(deg) +x2*Math.sin(deg)};
					pos2.lon = pos2.lon + x;
					pos2.lat = pos2.lat + y;
					wind_line.push([pos2.lon, pos2.lat]);
					wind_line.push([pos1.lon, pos1.lat]);
					f.push({type: "Feature", properties:{name: "wind", color: device.color, width: 2, opacity: 0.9},
									geometry:{type:"LineString",coordinates:[ wind_line[0], wind_line[1] ]}});
				}

				if(device.lines.length > 0)
					f.push(
						{type: "Feature", properties:{name: device.lastname, color: device.color}, geometry:{type:"LineString",coordinates:device.lines}},
					);
				});

				setGeojson({
					type: 'FeatureCollection',
					features: f
				});
			setGeojsonVersion(prevVersion => prevVersion + 1);
  }, [devices]);



	let onMove = function(arg){
		setBearing(arg.viewState.bearing)
		setPitch(arg.viewState.pitch)
		setViewState(arg.viewState)
	}


	//--- Deck GL -------------------------------------
  const [paths, setPaths] = useState();
  const [data, setData] = useState([]);
  const [buoy, setBuoy] = useState([]);
  const [spinnaker, setSpinnaker] = useState([]);
  const [hoveredObject, setHoveredObject] = useState(null);
  const [focusFlg, setFocusFlg] = useState(false);//一人でもフォーカスした選手がいるかどうか
 
	let convertPath = (lines, ele) =>{
		let out = [];
		lines.forEach((line, i)=>{
			out.push([line[0], line[1], ele]);
		});
		return out;
	}

  useEffect(() => {
      let lines = [];
      let devs = [];
      let buoys = [];
      let spins = [];
			let flg  = false;
      devices.forEach((device, i)=>{
				if(!device.showMarker)
					return;
				//device.getElevation();//標高データの取得・更新
				let elevationData=0;

				if(device.lon > 0)
					elevationData = Math.trunc(Number(mapRef.current.queryTerrainElevation([device.lon, device.lat], { exaggerated: false })));

				if(isAthlete(device.category)){
					// ranking配列からdevice.idと一致するidを持つ要素を見つける
					const rank = ranking ? ranking.find(rank => rank.id === device.id).rank : 0;
					devs.push(
						{id: device.id, rank: rank, no: device.no,  name: device.disp_name, speed: device.speed, color: device.color, wind_angle: wind?wind.wind_direction:0 - device.angle,
						 focus: device.ischecked, position: [device.lon, device.lat, elevationData + ele_offset], angle: -device.angle, ele: elevationData},
					);
					if(device.ischecked){
						if(!flg) {
							flg = true
						}
						
					}
					let angleDifference = wind?wind.wind_direction - device.angle:0
					if (angleDifference > 180) {
							angleDifference -= 360;
					} else if (angleDifference < -180) {
							angleDifference += 360;
					}
					if(Math.abs(angleDifference) > 140){
						spins.push(
							{id: device.id, no: device.no,  name: device.disp_name, speed: device.speed, color: device.color, wind_angle: wind?wind.wind_direction:0 - device.angle,
							 focus: device.ischecked, position: [device.lon, device.lat, elevationData + ele_offset], angle: -device.angle, ele: elevationData},
						);
					}
				}else {
					buoys.push(
						{id: device.id, no: device.no,  name: device.disp_name, speed: device.speed, color: device.disp_name==="G"? "#ff0000":"#ffd900", wind_direction: wind?wind.wind_direction:0,
						 focus: device.ischecked, position: [device.lon, device.lat, elevationData + ele_offset], angle: -device.angle, ele: elevationData},
					);
				}
				if(device.lines.length > 0){
					lines.push(
						{name: device.disp_name, color: device.color, path: convertPath(device.lines, device.elevation + ele_offset) },
					);
				}
      });
      setData(devs);
      setBuoy(buoys);
      setSpinnaker(spins);
      setPaths(lines);
			setFocusFlg(flg);
  }, [devices]);
	//------------

	//--- Deck GL -------------------------------------
	const linelayer = new GeoJsonLayer({
		id: 'geojson-layer',
		data: geojson,
		pickable: false,
		stroked: false,
		filled: false,
		lineWidthScale: 1,
		lineWidthMinPixels: 1,
		getLineColor: d => {
			if (d.properties && d.properties.color) {
				// 16進数の色文字列を RGB 配列に変換
				const color = d.properties.color.replace('#', '');
				const r = parseInt(color.substr(0, 2), 16);
				const g = parseInt(color.substr(2, 2), 16);
				const b = parseInt(color.substr(4, 2), 16);
				return [r, g, b];
			}
			return [0, 0, 0]; // デフォルト色（黒）
		},
		getLineWidth: 1
	});

	let layers = [createMarkerLayers(setWindLine, setHoveredObject, devices, setDevices, data, buoy, spinnaker, focusFlg)];
	const windDirection = wind ? wind.wind_direction : 0;
	layers = [...layers, ArrowLayer({ center, windDirection }), linelayer]

  //自動フォーカスを解除
	let onDrag = function(arg){
    clearDevices();
  }

	// --- Render ---------------------------------------
  return (
    <Map
			style={{height:height}}
			ref={mapRef}
      mapboxAccessToken={TOKEN}
			width="100%"
			height={height}
      {...viewState}
      onMove={onMove}
      mapStyle={style}
			onLoad={handleMapLoad}
      onDragStart={onDrag}
    >

			<WindInfo wind={wind} bearing={bearing} />
			{ false && (
			<>
				<Source key={geojsonVersion}  type="geojson" data={geojson}>
					<Layer {...lineLayer} />
				</Source> </>)
			}
			<Source type="geojson" data={StartGoal}>
				<Layer {...StartGoalLineLayer} />
				<Layer {...SymbolLayer} />
			</Source>
			{ course && course.geojson && <CourseMap visibleRoutes={visibleRoutes} courseGeojson={course.geojson} />}
			<StartGoalFlag startTwoCoords={startTwoCoords} goalTwoCoords={goalTwoCoords}/>
			<GeolocateControl position={"top-left"} />
			<FullscreenControl  position={"top-left"} />
			<NavigationControl position={"top-left"} />
			<Menu setStyle={setStyle} visibleRoutes={visibleRoutes} setVisibleRoutes={setVisibleRoutes}
						focusCamera={focusCamera} setFocusCamera={setFocusCamera}/>
			<ScaleControl  />


			<DeckGLOverlay layers={layers} />

     {hoveredObject && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            pointerEvents: 'none',
            left: '50%',
            bottom: '10%',
            padding: '8px',
            background: 'white',
            border: '1px solid #ccc',
          }}
        >
					<div>
						<div>NAME: {hoveredObject.name}</div>
						<div>VELOCITY: {hoveredObject.speed} km/h</div>
						<div>ELEVATION: {hoveredObject.ele} m</div>
						<div>3D Height: {hoveredObject.position[2]} m</div>
						<div>WIND ANGLE:{hoveredObject.wind_angle} </div>
					</div>
        </div>
      )}
		</Map>
  );
}

export default DeckGLView;
