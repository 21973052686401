import API from './API.js';
import Device from './device';
import pako from 'pako';
import axios from 'axios';

// initialize_replay
export function init_replay(setDevices, setCenter, setOnline, replayDataUrl, setDataRange, setCurrentReplayTimeSec, setWind, setCourse, setSpinner, setStartedTime, setPassedTimes){

	function loadJson(r){
		// データが存在するか、配列であるかを確認
		if (!r) {
			alert('loadJson: Data is undefined or null');
			return;
		}

    //Devices
    let deviceMap = new Map();
		let lastDevice;
    r.devices.map(function(d, i) {
      deviceMap.set( d.id, new Device(d.id, d));
			lastDevice = d;
    });
    setDevices(deviceMap);
		if(r.course.start_lat != ""){ //コースが設定されている
      let center = [(Number(r.course.start_lat) + Number(r.course.end_lat)) /2, (Number(r.course.start_long) + Number(r.course.end_long)) /2];
      setCenter(center);
		}else{
			let g = lastDevice.gpses.slice(-1)[0];
			let center = [(Number(g.lat) ) , (Number(g.lon)) ];
      setCenter(center);
		}

		//Course
		if(r.course.checkpoints_string != ""){
			let checkpoints = JSON.parse(r.course.checkpoints_string)
			setCourse({
				net             : r.course.net,
				goal_start_lat  : r.course.goal_start_lat,
				goal_start_long : r.course.goal_start_long,
				goal_end_lat    : r.course.goal_end_lat,
				goal_end_long   : r.course.goal_end_long,
				checkpoints     : checkpoints,
				geojson         : r.course.geojson ?  JSON.parse(r.course.geojson):[],
				})
		}

    //Start Time
    setStartedTime(r.started_at);

    //Passed Time
    setPassedTimes(r.passed_times);

    //Slider
    var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
    let i;
    deviceMap.forEach((d, i) => {
      let time = d.max_time();
      if (time > max_time){
        max_time = time
      }
    });
    var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
    deviceMap.forEach((d, i) => {
      let time = d.min_time();
      if (time < min_time){
        min_time = time
      }
    });

    //Setter Slider
    setDataRange({
        min: min_time/1000,
        max: max_time/1000,
        step: 1
    });
    setCurrentReplayTimeSec(min_time/1000);

    //Wind Information
    if(r.wind_direction && r.max_wind){
      setWind({
        "wind_direction" : r.wind_direction,
        "max_wind" : r.max_wind,
        "min_wind" : r.min_wind} );
    } else if (r.wind_direction ){
      setWind({ "wind_direction" : r.wind_direction});
    }

    setOnline({replay: true});
    setSpinner(false);
	}


    // データを取得する関数
    const fetchData = async () => {
      try {
        // Gzip圧縮されたファイルを取得
        const response = await axios.get(replayDataUrl, {
          responseType: 'arraybuffer', // ArrayBufferとしてレスポンスを取得
        });

        // ArrayBufferをUint8Arrayに変換
        const buffer = new Uint8Array(response.data);

        // Gzipのマジックナンバーをチェック
        const isGzip = buffer[0] === 0x1f && buffer[1] === 0x8b;

        if (isGzip) {
          // Gzipデータの場合、解凍処理を行う
          const decompressedData = pako.inflate(buffer, { to: 'string' });
          const jsonData = JSON.parse(decompressedData);
					loadJson(jsonData);
        } else {
          // 通常のJSONデータの場合、そのまま読み込む
          const jsonData = JSON.parse(new TextDecoder().decode(buffer));
					loadJson(jsonData);
        }
      } catch (err) {
				console.log(err);
      }
    };

    fetchData();

}
