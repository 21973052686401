import * as React from 'react';
import {useState, useEffect} from 'react';
import Animation from './animation';
import AnimationSphere from './animation_sphere';
import AnimationBuoy from './animation_buoy';
import AnimationCar from './animation_car';
import AnimationDrone from './animation_dokuro';
import AnimationCamera from './animation_camera';
import AnimationAED from './animation_AED';
import { isAthlete, isCamera } from '../../initialize/device_helpers';

const SIZE = 20;

var upd_time =  new Date();

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Sups(props) {
  const {devices, bearing, focusCamera, is_triathlon_view, handleClickMarker, ranking, focusFlg, setWindLine} = props;

  const [animate_flg, setAnimateFlg] = useState(false);
  const sups = [];
	const [updTime, setUpdTime] = useState(new Date());

  // マーカー移動の制御
  // 1秒以内の更新は移動しない -> 処理が重くなるため
  // main内、setIntervalの仕様変更により、短時間に複数回呼ばれることがあるため
  useEffect(() => {
		if(new Date() - upd_time < 1000){
			setAnimateFlg(false);
			return ;
		}
		//console.log(new Date() - upd_time);
		upd_time = new Date();
		setAnimateFlg(true);
	},[devices]);

	//console.log(animate_flg)

  if(!devices)
    return null;

  devices.forEach((device, i)=>{

    if(isAthlete(device.category)){
      sups.push(<Animation
                  key={device.id}
                  id={device.id}
                  rank={(() => {
                      if (!ranking)
                          return null
                      const foundRank = ranking.find(r => r.id === device.id)?.rank;
                      return foundRank && foundRank <= 10 ? foundRank : null;
                  })()}
                  name={device.fullname}
                  no={device.no}
                  focus={device.ischecked}
                  focusFlg={focusFlg}
                  showMarker={focusFlg?(device.focus? device.showMarker: false):  device.showMarker }
                  bearing={bearing}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
                  setWindLine={setWindLine}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}}
                  animate_flg={animate_flg}
                  category={device.category}
                  emergency={device.emergency}
									handleClickMarker={handleClickMarker}
                  is_triathlon_view={is_triathlon_view} />);
    } else if(device.category == 2){
      sups.push(<AnimationSphere
                  key={device.id}
                  id={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
									handleClickMarker={handleClickMarker}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);

    } else if(device.category == 3){
      sups.push(<AnimationBuoy
                  key={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={"#ffff00"}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);
    } else if(device.category == 4){
      sups.push(<AnimationBuoy
                  key={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={"#ff4444"}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);
    } else if(device.category == 6){
      sups.push(<AnimationDrone
                  key={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);
    } else if(device.category == 7){
      sups.push(<AnimationCar
                  key={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);
    } else if(isCamera(device.category)){
      sups.push(<AnimationCamera
                  key={device.id}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}}
                  name={device.disp_name}
                  focus={focusCamera}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
                  animate_flg={animate_flg}/>);
    } else {
      sups.push(<Animation
                  key={device.id}
                  name={device.disp_name}
                  speed={device.speed_5s}
                  time={device.time}
                  color={device.color}
                  position={{lat:device.lat, lon:device.lon, angle: device.angle}} />);
    }
  });
  return sups;
}

export default React.memo(Sups);
