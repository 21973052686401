import * as React from 'react';
import {render} from 'react-dom';
import Main from    './components/main/main';
import Ranking from './components/ranking_view/main';
import WWC from './components/main/wwc';
import BikeSpeed from './components/main/bike_speed';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// App 本体
export default function App() {

  return (
		<Router>
			<Routes>
				<Route path="/" element={<Main />} />
				<Route path="ranking" element={<Ranking />} />
				<Route path="wwc" element={<WWC />} />
				<Route path="bike_speed" element={<BikeSpeed />} />
			</Routes>
		</Router>
  );
}

export function renderToDom(container) {
  render(<App />, container);
}

