import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { setDelay } from '../../initialize/websocket.js';
import { is_counttime } from '../../helpers';

export default function DelayControl(props) {
  const { is_mobile, started_at } = props;
  const startTime = new Date(started_at || 0);
  const [dataRange, setDataRange] = useState({
    min: -4200,
    max: 0,
    step: 1,
  });
  const [timeOffset, setTimeOffset] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
      const fiveMinutesBeforeStartTime = new Date(startTime.getTime() - 5 * 60 * 1000);
      const maxSliderValue = Math.floor((fiveMinutesBeforeStartTime.getTime() - currentTime.getTime()) / 1000);
      setDataRange(prevRange => ({
        ...prevRange,
        min: maxSliderValue,  // 動的に最大値を設定
      }));
  }, [currentTime]);


  // フォーマットする関数
  const formatTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  const formatTimeJST = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };


  // スライダーの値が変わったときの処理
  const handleSliderChange = (event, newValue) => {
    setTimeOffset(-newValue);  // 新しい値に基づいてtimeOffsetを更新
    setDelay(-newValue);
  };

  // 経過時間または残り時間の計算
  const isFuture = startTime > currentTime;
  const timeDifference = isFuture
    ? currentTime.getTime()
    : currentTime.getTime() - timeOffset * 1000;

  const displayTime = is_counttime()
    ? isFuture
      ? formatTime(new Date(startTime.getTime()-timeDifference))
      : formatTime(new Date(timeDifference -  startTime.getTime()))
    : formatTimeJST(new Date(timeDifference));

  const isAtRightEnd = -timeOffset === dataRange.max;
  const liveDotColor = isAtRightEnd ? 'red' : 'rgba(255, 0, 0, 0.5)';

  return (
    <Grid container spacing={1}> 
      <Grid item xs={is_mobile ? 3 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }} className={'nowtime'}>
        {displayTime}
      </Grid>
      <Grid item xs={is_mobile ? 7 : 10} style={{ backgroundColor: '#000000' }}>
        <Slider
          min={dataRange.min}
          max={dataRange.max}
          step={dataRange.step}
          value={-timeOffset}
          onChange={handleSliderChange}
          aria-labelledby="continuous-slider"
        />
      </Grid>
      <Grid item xs={is_mobile ? 2 : 1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }}>
        <span style={{ color: liveDotColor, marginRight: '4px' }}>●</span>
        <span style={{ color: '#ffffff' }}>LIVE</span>
      </Grid>     
    </Grid>
  );
}

