import {PathLayer, TextLayer, LineLayer} from '@deck.gl/layers';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
import { easeCubicInOut } from 'd3-ease';

const createMarkerLayers = (setWindLine, setHoveredObject, devices, setDevices, data, buoy, spinnaker, focusFlg) => {
	//const MODEL_URL='/glb/arrow.glb';
	//const MODEL_URL='/glb/sail-boat2.glb';
	const MODEL_URL='/glb/470.glb';
	//const MODEL_URL='/glb/windfoil.glb';
	const BUOY_URL='/glb/buoy.glb';
	//const BUOY_URL='/glb/wind_arrow.glb';
	const SPIN_URL='/glb/spinnaker.glb';

	const AnimateT = 350;
	const Height = 70;

	const textWidth = 50;  // テキストの幅（ピクセル単位で調整が必要）
	const textHeight = 20; // テキストの高さ（ピクセル単位で調整が必要）

  return [
		//470
		new ScenegraphLayer({
			id: 'scenegraph-layer',
			data: data,
			pickable: true,
			sizeScale: 4,
			//getSize: 50, // 追加: 3Dモデルのサイズをピクセル単位で指定
			sizeMinPixels: 1.5, // この値で最小サイズの制約を設定
			sizeMaxPixels: 15,  // この値で最大サイズの制約を設定
			scenegraph: MODEL_URL,
			//getColor: d => d.color ? d.color.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16)) : [0, 0, 0],
			getColor: d => [255,255,255,255],
			getPosition: d => [
				d.position[0],
				d.position[1],
				d.position[2]
			],
			getOrientation: d => [0, d.angle, 90],
			transitions: {
				getPosition: AnimateT,
				easing: easeCubicInOut // よりスムーズなトランジション
			},
			_lighting: 'pbr',
			onHover: ({ object, x, y }) => {
				if (object) {
					// マウスカーソルがポイントにホバーしている場合
					setHoveredObject(object);
				} else {
					// マウスカーソルがポイントから離れた場合
					setHoveredObject(null);
				}
			},
			onClick: ({ object, x, y }) => {
				if (object) {
          let device = devices.get(object.id);
					setDevices(object.id);
					setWindLine({deviceId: object.id});
				}
			},
		}),
	
		//Rank
		new TextLayer({
			id: 'text-layer',
			data: focusFlg? data.filter(d => d.focus && d.rank !==0) : data.filter(d => (0<d.rank && d.rank < 11)), //フィルター 0位は表示しない
			//data: data.filter(d => d.rank !== 0), //フィルター 0位は表示しない
			pickable: true,
			getPosition: d => [d.position[0], d.position[1], d.position[2] + Height],
			getText: d => `${d.rank.toString().padEnd(2, ' ')}`,
			//getSize: d=> d.focus ? 32 : 20,
			getSize: 12,
			getAngle: 0,
			getColor: d => [0,0,0,255],
			getTextAnchor: 'middle',
			getAlignmentBaseline: 'top',
			getPixelOffset: d => [-14, 0],
			background: true,
			getTextAnchor: 'start', // 左端を基準に表示
			getAlignmentBaseline: 'top',
			getBackgroundColor: [255, 125, 0, 255], // 背景カラー（白）
			transitions: {
				getPosition: AnimateT,
				easing: easeCubicInOut // よりスムーズなトランジション
			},
			onClick: ({ object, x, y }) => {
				if (object) {
          let device = devices.get(object.id);
					setDevices(object.id);
					setWindLine({deviceId: object.id});
				}
			},
		}),


		//Athlete No
		new TextLayer({
			id: 'text-layer',
			//data: data,
			data: focusFlg? data.filter(d => d.focus) : data,
			pickable: true,
			getPosition: d => [d.position[0], d.position[1], d.position[2] + Height],
			getText: d => d.no.padEnd(6, ' '),
			//getSize: d=> d.focus ? 32 : 20,
			getSize: 12,
			getAngle: 0,
			getColor: d => d.focus ? [238,120,0,255] : [25,25,25,255],
			getTextAnchor: 'middle',
			getAlignmentBaseline: 'top',
			getPixelOffset: d => [0, 0],
			background: true,
			getTextAnchor: 'start', // 左端を基準に表示
			getAlignmentBaseline: 'top',
			// getPixelOffsetを使って、表示の幅の1/2を右にずらす
			getPixelOffset: d => {
				const text = d.no.padEnd(6, ' '); // 表示するテキスト
				const fontSize = 12; // フォントサイズ
				const textLength = text.length; // テキストの長さ
				const textWidth = textLength * fontSize * 0.01; // 大まかなテキスト幅の計算（文字幅の係数を0.6として推定）
				return [textWidth / 2, 0]; // テキスト幅の1/2を右にずらす
			},
			//getBackgroundColor: [0,0,0,170],
			getBackgroundColor: [255, 255, 255, 255], // 背景カラー（白）
			//sizeMinPixels: 13,
			//fontFamily: 'Noto Sans JP',
			fontFamily: 'Noto Sans JP, Light',  // 細いフォントを使用
			transitions: {
				getPosition: AnimateT,
				easing: easeCubicInOut // よりスムーズなトランジション
			},
			onClick: ({ object, x, y }) => {
				if (object) {
          let device = devices.get(object.id);
					setDevices(object.id);
					setWindLine({deviceId: object.id});
				}
			},
		}),

		//Line to Athlete No
		new LineLayer({
			id: 'line-layer',
			data: focusFlg? data.filter(d => d.focus) : data,
			getSourcePosition: d => [d.position[0], d.position[1], d.position[2] + 20],
			getTargetPosition: d => [d.position[0], d.position[1], d.position[2] + Height], // テキスト位置
			getColor: [90, 90, 90, 255], // 線の色
			getWidth: 1 // 線の太さ
		}),

	//SPINNAKER
		new ScenegraphLayer({
			id: 'scenegraph-layer2',
			data: spinnaker,
			pickable: true,
			sizeScale: 3,
			//getSize: 50, // 追加: 3Dモデルのサイズをピクセル単位で指定
			sizeMinPixels: 1.5, // この値で最小サイズの制約を設定
			sizeMaxPixels: 15,  // この値で最大サイズの制約を設定
			scenegraph: SPIN_URL,
			getColor: d => d.color ? d.color.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16)) : [0, 0, 0],
			getPosition: d => [
				d.position[0] || 0,
				d.position[1] || 0,
				d.position[2] || 0
			],
			getOrientation: d => [0, d.angle, 90],
			//_lighting: 'pbr',
			onHover: ({ object, x, y }) => {
				if (object) {
					// マウスカーソルがポイントにホバーしている場合
					setHoveredObject(object);
				} else {
					// マウスカーソルがポイントから離れた場合
					setHoveredObject(null);
				}
			},
			onClick: ({ object, x, y }) => {
				if (object) {
          let device = devices.get(object.id);
					setDevices(object.id);
				}
			},
		}),
	
		//Buoy
		new ScenegraphLayer({
			id: 'scenegraph-layer',
			data: buoy,
			pickable: true,
			sizeScale: 5,
			sizeMinPixels: 1.5, // この値で最小サイズの制約を設定
			sizeMaxPixels: 15,  // この値で最大サイズの制約を設定
			scenegraph: BUOY_URL,
			//getColor: d => [255,217,0,255],
			getColor: d => d.color ? d.color.match(/[0-9a-f]{2}/g).map(x => parseInt(x, 16)) : [0, 0, 0],
			getPosition: d => [
				d.position[0] || 0,
				d.position[1] || 0,
				d.position[2] || 0
			],
			getOrientation: d => [0, d.angle, 90],
			transitions: {
				getPosition: AnimateT,
				easing: easeCubicInOut // よりスムーズなトランジション
			},
		}),
		new TextLayer({
			id: 'text-layer',
			data: buoy,
			getPosition: d => [d.position[0], d.position[1], d.position[2] + 50],
			getText: d => d.name,
			getSize: 14,
			getAngle: 0,
			getColor: d => d.focus ? [238,120,0,255] : [255,255,255,255],
			transitions: {
				getPosition: AnimateT,
			},
		}),
	]
};

export default createMarkerLayers;

